/**
 * Collection Quickview
 */
function collectionQuickview(opts) {
  const config = {
    dom: {
      collection: 'collection',
      heartBtn: 'js-product-heart',
      productCarousel: 'js-product-item-carousel',
      productItem: 'js-pi',
      swatchBtn: 'js-product-swatch',
    },
    cls: {
      hasQuickview: 'has-quickview',
      selected: 'is-selected',
      init: 'is-initialized',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts);

  // Cached Globals
  const $main = document.getElementById(c.dom.collection);
  const $productItems = document.querySelectorAll(`.${c.dom.productItem}:not(.${c.cls.init})`);
  let hasQuickview = __.hasClass($main, c.cls.hasQuickview);
  const uploadedArtwork = localStorage.getItem('uploadedArtwork');
  const hasUploadedArtwork = uploadedArtwork !== null && uploadedArtwork !== '';

  if (hasUploadedArtwork) {
    hasQuickview = true;
  }

  /**
   * Init
   */
  function init() {
    if (!$productItems?.length || !hasQuickview) return;
    addEvents();
  }

  /**
   * Add Events
   */
  function addEvents() {
    $productItems.forEach(($item) => {
      __.addClass($item, c.cls.init);
      const quickview = SDG.quickview({
        type: 'single',
        wrapper: $item,
      });

      quickview.init();

      __.addEvent({
        id: $item,
        event: 'click',
        fn: (e) => handleQuickviewOpen(e, quickview, $item),
      });
    });
  }

  /**
   * Handle Quickview Open
   * @param {Object} e - click event
   * @param {Object} quickview instance
   * @param {Element} $item product item
   */
  function handleQuickviewOpen(e, quickview, $item) {
    const { target } = e;
    const $selectedSwatch = $item.querySelector(`.${c.dom.swatchBtn}.${c.cls.selected}`);
    const selectedColor = $selectedSwatch?.dataset?.color;

    // exclude selectors
    const isCarousel = __.hasClass(target, c.dom.productCarousel)
      || __.parents(target, `.${c.dom.productCarousel}`)?.length;
    const isHeartBtn = __.hasClass(target, c.dom.heartBtn)
      || __.parents(target, `.${c.dom.heartBtn}`)?.length;
    const isSwatchBtn = __.hasClass(target, c.dom.swatchBtn)
      || __.parents(target, `.${c.dom.swatchBtn}`)?.length;

    // check for exclude selectors
    const isExcludeSelector = (
      isCarousel
      || isHeartBtn
      || isSwatchBtn
    );
    if (isExcludeSelector) return;
    // open quickview drawer
    quickview.open(e, selectedColor);
  }

  return init();
}

export default collectionQuickview;
