import { determineSizeDefaults } from "./framingUtilities";
import { cover } from "intrinsic-scale";

export const setArtworkSizeDefaults  = (artwork, args) => {
  const sizeDefaults = determineSizeDefaults(artwork.customerSpecified.dimensionsInPixels, args);
  const widthInInches = sizeDefaults?.widthInInches;
  const heightInInches = sizeDefaults?.heightInInches;

  return {
    ...artwork,
    exterior: {
      widthInInches: widthInInches,
      heightInInches: heightInInches
    },
    offset: {
      topInInches: "auto",
      leftInInches: "auto"
    }
  };
};

export const setArtworkDefaults  = (artwork) => {
    return {
      ...artwork,
      offset: {
        topInInches: "auto",
        leftInInches: "auto"
      }
  };
};

export function determineCropGeometryToMatchRatio({
  boundingWidth,
  boundingHeight,
  imageWidth,
  imageHeight
}) {

  const imagePositioning = cover(
    boundingWidth,
    boundingHeight,
    imageWidth,
    imageHeight
  );

  const finalImageWidth = imagePositioning.width + imagePositioning.x * 2;
  const finalImageHeight = imagePositioning.height + imagePositioning.y * 2;

  return {
    x_offset: Math.abs(imagePositioning.x),
    y_offset: Math.abs(imagePositioning.y),
    width: finalImageWidth,
    height: finalImageHeight,
    crop_ui_size: {
      width: imagePositioning.width,
      height: imagePositioning.height
    }
  };
}
