<template>
  <ul class="swatches list-reset product-item__swatches js-item-swatches">
    <li
      v-for="(color) in swatches"
      :key="color.color"
      class="swatches__item js-product-swatch-wrapper"
      :class="{'is-selected': color.color === activeColor.color}"
    >
      <button
        class="swatches__btn btn-icon js-product-swatch product-item__swatch"
        :class="{'is-selected': color.color === activeColor.color}"
        :data-color="color.color"
        :data-image="color.featured_image?.src || missingImage"
        :data-id="color?.featured_image?.variant_ids ? color?.featured_image?.variant_ids[0] : ''"
        type="button"
        :aria-pressed="[color.color === activeColor.color ? 'true': 'false']"
        @click="updateImage"
      >
        <div
          class="swatch"
        >
          <span
            v-if="color.type === 'image'"
          >
            <ResponsiveImage
              :image="color.value"
              :image-alt="color.color"
              modifier="swatch"
            />
          </span>
          <div
            v-else
            class="ir ir--swatch swatches__btn-swatch"
            :style="{background: color.value}"
          >
            <span class="screenreader">{{ color.color }}</span>
          </div>
          <div class="swatch__active"></div>
        </div>
      </button>
    </li>
    <li
      v-if="swatchAdditonalText"
      class="swatches__item-additional"
    >
      <p>
        {{ swatchAdditonalText }}
      </p>
    </li>
  </ul>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';

export default {
  name: 'ProductSwatches',
  components: {
    ResponsiveImage,
  },
  props: {
    colors: {
      type: Array,
      required: true,
    },
    activeColor: {
      type: Object,
      required: true,
    },
    initialColor: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    noImage: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    updateImage: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      colors,
      noImage,
      initialColor,
    } = toRefs(props);
    const limit = ref(5);
    const totalSwatches = ref(colors?.value?.length || 0);
    const missingImage = noImage.value;

    const swatches = computed(() => {
      const productSwatches = colors.value;
      if (productSwatches?.length > limit?.value) {
        const slicedSwatches = productSwatches.slice(0, limit.value);
        const initColorValue = initialColor.value.color;
        const colorIndex = productSwatches.findIndex((swatch) => swatch.color === initColorValue);
        if (initColorValue && colorIndex >= limit.value) {
          // set initial color to be last visible swatch
          slicedSwatches[slicedSwatches.length - 1] = productSwatches[colorIndex];
        }
        return slicedSwatches;
      }

      return productSwatches;
    });

    const swatchAdditonalText = computed(() => {
      if (!limit.value || !totalSwatches.value) return false;

      if (totalSwatches.value > limit.value) {
        return `+ ${totalSwatches.value - limit.value} more`;
      }

      if (totalSwatches.value > 1) {
        return `${totalSwatches.value} styles`;
      }

      if (totalSwatches.value === 1) {
        return `${totalSwatches.value} style`;
      }

      return false;
    });

    return {
      swatches,
      totalSwatches,
      swatchAdditonalText,
      limit,
      missingImage,
    };
  },
};
</script>
