import libheif from 'libheif-js';

export const heicToJpeg = async (file) => {
  const arrayBuffer = await file.arrayBuffer();
  const decoder = new libheif.HeifDecoder();
  const data = decoder.decode(arrayBuffer);
  // Extract the first image from the HEIF container
  const image = data[0];
  const width = image.get_width();
  const height = image.get_height();
  // Create a canvas element
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  const imageData = context.createImageData(width, height);
  // Display the image on the canvas
  await new Promise((resolve, reject) => {
    image.display(imageData, (displayData) => {
      if (!displayData) {
        return reject(new Error('HEIF processing error'));
      }
      resolve();
    });
  });
  context.putImageData(imageData, 0, 0);
  // Convert canvas to Blob
  return await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 1));
};
