<template>
  <div
    class="pi__img"
    :class="{
      'is-loading': isUploadProcessing
        || (frameSpec && !showUploadedImage && !isBundle) || isLoading,
      'is-error': (frameSpec && customerUploadActive && !canPrintArtwork),
    }"
  >
    <div
      v-if="(frameSpec && customerUploadActive && !canPrintArtwork)"
      class="product-item__image--error"
    >
    </div>
    <div
      v-if="(frameSpec && customerUploadActive && !canPrintArtwork)"
      class="product-item__image--message"
    >
      <p class="product-item__image--message-text">{{ errorUploadMessage }}</p>
    </div>
    <div
      v-if="badge"
      class="pi__product-badge"
    >
      <p>
        {{ badge }}
      </p>
    </div>
    <a
      v-if="!isQuickview"
      :href="url && !isQuickview ? url : null"
      :title="url"
       class="pi__link"
    >
      <div
        v-if="(frameSpec && !isBundle)"
        ref="customUpload"
        :data-fs-url="frameSpecUrl"
        class="product-item__image--upload pi__image pi__image--upload js-uploaded-image"
        :class="{'is-visible' : showUploadedImage}"
        >
          <MaskedRenderer
            v-if="frameSpec && hasMaskedRendering && canPrintArtwork"
            :frameSpec="frameSpec"
            class="content masked-renderer"
          />
          <SingleFramePreviewSvg
            v-else
            :frameSpec="frameSpec"
            class="content single-frame-preview-svg"
          />

      </div>
      <ResponsiveImage
        v-if="!showUploadedImage"
        :image="src"
        :image-alt="parseImageAlt(alt)"
        wrapper-class="product-item__image pi__image"
        :class="{
          'pi__image--static':
            parseImages(productImages).length > 1 && !disableCarousel
        }"
        image-class="js-plp-image"
        modifier="product-item"
      />
      <div
        v-if="parseImages(productImages).length > 1 && !disableCarousel"
        class="pi__image-carousel-wrapper"
      >
        <div class="pi__image-carousel js-product-item-carousel">
          <ResponsiveImage
            v-for="image in parseImages(productImages)"
            :key="image.id"
            :image="image.src"
            :image-alt="parseImageAlt(image.alt)"
            wrapper-class="product-item__image
              pi__image-carousel-cell js-product-item-carousel-cell"
            image-class="js-plp-image"
            modifier="product-item"
          />
        </div>
      </div>
    </a>

    <div
      v-else
      class="pi__link"
    >
      <div
        v-if="(frameSpec && !isBundle)"
        ref="customUpload"
        :data-fs-url="frameSpecUrl"
        class="product-item__image--upload pi__image pi__image--upload js-uploaded-image"
        :class="{'is-visible' : showUploadedImage}"
      >
        <MaskedRenderer
          data-test="masked-renderer"
          v-if="frameSpec && hasMaskedRendering && canPrintArtwork"
          :frameSpec="frameSpec"
          class="content masked-renderer"
        />
        <SingleFramePreviewSvg
          data-test="single-frame-preview-svg"
          v-else
          :frameSpec="frameSpec"
          class="content single-frame-preview-svg"
        />
      </div>
      <ResponsiveImage
        v-if="!showUploadedImage"
        :image="src"
        :image-alt="parseImageAlt(alt)"
        wrapper-class="product-item__image pi__image"
        :class="{
          'pi__image--static':
            parseImages(productImages).length > 1 && !disableCarousel
        }"
        image-class="js-plp-image"
        modifier="product-item"
      />
      <div
        v-if="parseImages(productImages).length > 1 && !disableCarousel"
        class="pi__image-carousel-wrapper"
        @mouseenter="hover(true)"
        @mouseleave="hover(false)"
      >
        <div
          ref="carouselRef"
          class="pi__image-carousel js-product-item-carousel"
        >
          <ResponsiveImage
            v-for="image in parseImages(productImages)"
            :key="image.id"
            :image="image.src"
            :image-alt="parseImageAlt(image.alt)"
            wrapper-class="product-item__image
              pi__image-carousel-cell js-product-item-carousel-cell"
            image-class="js-plp-image"
            modifier="product-item"
          />
        </div>
      </div>
    </div>
    <div
      v-if="compareEnabled && isCollectionPage"
      class="pi__heart"
    >
      <button
        v-if="type !== 'Gallery Wall Consultation' && type !== 'Gift Card'"
        class="pi__heart-btn js-product-heart btn-icon"
        type="button"
        tabindex="0"
      >
        <span class="screenreader">Favorite Icon</span>
        <span class="pi__heart-icon icon icon-default icon--heart-icon"></span>
        <span class="pi__heart-icon icon icon-active icon--heart-icon-dark"></span>
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  toRefs,
  onMounted,
  onUpdated,
  watchEffect,
  watch,
} from 'vue';
import Flickity from 'flickity';
// Components
import SingleFramePreviewSvg from '@framebridge/toolbox/SingleFramePreviewSvg';
import MaskedRenderer from '@framebridge/toolbox/MaskedRenderer';
import ResponsiveImage from '../common/responsive-image/ResponsiveImage';

const props = defineProps({
  activeColor: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  isUploadProcessing: {
    type: Boolean,
    required: false,
    default: false,
  },
  badge: {
    type: String,
    required: false,
    default: null,
  },
  errorUploadMessage: {
    type: String,
    required: false,
    default: null,
  },
  customerUploadActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  canPrintArtwork: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasMaskedRendering: {
    type: Boolean,
    required: false,
    default: false,
  },
  frameSpec: {
    type: Object,
    required: false,
    default: null,
  },
  frameSpecUrl: {
    type: String,
    required: false,
    default: null,
  },
  isBundle: {
    type: Boolean,
    required: false,
    default: false,
  },
  isQuickview: {
    type: Boolean,
    required: false,
    default: false,
  },
  productImages: {
    type: Array,
    required: false,
    default: () => [],
  },
  url: {
    type: String,
    required: false,
    default: null,
  },
  src: {
    type: String,
    required: false,
    default: null,
  },
  alt: {
    type: String,
    required: false,
    default: null,
  },
  type: {
    type: String,
    required: false,
    default: null,
  },
  isCollectionPage: {
    type: Boolean,
    required: false,
    default: false,
  },
  isLoading: {
    type: Boolean,
    required: true,
    default: false,
  },
  disableCarousel: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const {
  activeColor,
  canPrintArtwork, frameSpec,
  isBundle,
} = toRefs(props);
const showUploadedImage = ref(false);
const customUpload = ref(null);
const interval = ref(null);
const carouselRef = ref(null);
const compareEnabled = ref(SDG.Data.collectionsCompareEnabled);

// Methods
/**
 * Parse Image Alt
 * @param {Object} altText
 */
const parseImageAlt = (altText) => {
  if (!altText) return altText;
  const imageAltSplit = altText.includes('|') ? altText.split('|') : null;
  if (!imageAltSplit) return altText;
  const [, splitEnd] = imageAltSplit;
  return splitEnd || altText;
};

/**
 * Parse Images
 * @param {Object} images
 */
const parseImages = (images) => {
  const parsedImages = images?.filter((image) => {
    if (!image.alt) return false;
    const imageAltSplit = image.alt.includes('|') ? image.alt.split('|') : null;
    if (!imageAltSplit) return false;
    const [splitStart] = imageAltSplit;
    const splitStartColor = splitStart?.includes('/')
      ? splitStart.split('/')[0].toLowerCase().trim()
      : splitStart.toLowerCase().trim();
    const activeColorValue = activeColor.value.color || activeColor.value;
    const formattedSplitStartColor = splitStartColor.replace(/ /g, '_').replace(':', '');
    return (
      activeColorValue === formattedSplitStartColor
        || activeColorValue === splitStartColor
    );
  });
  return parsedImages;
};
const hover = __.debounce((val) => {
  const $carousel = Flickity.data(carouselRef.value) || null;
  if (!$carousel) return;

  if (val) {
    interval.value = setInterval(() => {
      $carousel.next();
    }, 1500);
  } else {
    clearInterval(interval.value);
    $carousel.select(0);
  }
}, 50);

const observeSVGs = (el) => {
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'viewBox') {
        showUploadedImage.value = true;
      }
      if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
        if (el.classList.contains('masked-renderer')) {
          showUploadedImage.value = true;
        }
      }
    });
  });

  observer.observe(el, {
    attributes: true,
    attributeFilter: ['viewBox', 'style'],
  });
};

// watchers
watch(
  [canPrintArtwork, frameSpec],
  (
    [newCanPrintArtwork, newFrameSpec],
    [oldCanPrintArtwork, oldFrameSpec],
  ) => {
    if (newCanPrintArtwork !== oldCanPrintArtwork) {
      if (!newCanPrintArtwork) {
        showUploadedImage.value = false;
      }
    }
    if (newFrameSpec !== oldFrameSpec) {
      if (!newFrameSpec) {
        showUploadedImage.value = false;
      }
    }
  },
  { immediate: true },
);

// lifecycle hooks
onMounted(() => {
  watchEffect(() => {
    if (customUpload.value) {
      const $svgElements = customUpload.value.querySelectorAll('svg') || [];
      const $maskedRenderers = customUpload.value.querySelectorAll('.masked-renderer') || [];

      if ($svgElements?.length > 0) {
        [...$svgElements].forEach((svgElement) => observeSVGs(svgElement));
      }

      if ($maskedRenderers?.length > 0) {
        [...$maskedRenderers].forEach((maskedRenderer) => {
          observeSVGs(maskedRenderer);
        });
      }
    }
  });
});

onUpdated(() => {
  if (customUpload.value) {
    const $svgElements = customUpload.value.querySelectorAll('svg') || [];
    const $maskedRenderers = customUpload.value.querySelectorAll('.masked-renderer') || [];

    if ($svgElements?.length > 0) {
      [...$svgElements].forEach((svgElement) => observeSVGs(svgElement));
    }

    if ($maskedRenderers?.length > 0) {
      [...$maskedRenderers].forEach((maskedRenderer) => {
        observeSVGs(maskedRenderer);
      });
    }
  }
});
</script>
