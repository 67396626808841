<template>
  <div class="relative mask-wrapper" :style="maskWrapperCssProps">
    <div class="mask absolute" :style="maskCssProps"/>
    <svg width="640" height="640" :style="svgCssProps">
      <g :transform="translateOffset">
        <image
          x="0"
          y="0"
          :width="imageWidth"
          :height="imageHeight"
          preserveAspectRatio="xMidYMid slice"
          :xlink:href="src"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { lusolve } from "mathjs";
import round from "lodash/round";
import { contain } from "intrinsic-scale";

function getInnerSize(node) {
  if (node) {
    const style = getComputedStyle(node);
    const {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      borderLeftWidth,
      borderRightWidth,
      borderTopWidth,
      borderBottomWidth
    } = style;
    const paddingX = parseFloat(paddingLeft) + parseFloat(paddingRight);
    const paddingY = parseFloat(paddingTop) + parseFloat(paddingBottom);

    const borderX = parseFloat(borderLeftWidth) + parseFloat(borderRightWidth);
    const borderY = parseFloat(borderTopWidth) + parseFloat(borderBottomWidth);

    // Element width and height minus padding and border
    const width = node.offsetWidth - paddingX - borderX;
    const height = node.offsetHeight - paddingY - borderY;

    return { width, height };
  }
}

// A set of points to define the images four corners and goal perspective
function matrixTransformStyle(sourcePoints, targetPoints) {
  const alpha = [];
  const beta = [];
  const pointsCount = sourcePoints.length;
  for (let index = 0; index < pointsCount; ++index) {
    const sourcePoint = sourcePoints[index];
    const targetPoint = targetPoints[index];
    alpha.push([
      sourcePoint[0],
      sourcePoint[1],
      1,
      0,
      0,
      0,
      -sourcePoint[0] * targetPoint[0],
      -sourcePoint[1] * targetPoint[0]
    ]);
    beta.push(targetPoint[0]);
    alpha.push([
      0,
      0,
      0,
      sourcePoint[0],
      sourcePoint[1],
      1,
      -sourcePoint[0] * targetPoint[1],
      -sourcePoint[1] * targetPoint[1]
    ]);
    beta.push(targetPoint[1]);
  }

  const output = lusolve(alpha, beta);
  const matrix = [
    output[0],
    output[3],
    0,
    output[6],
    output[1],
    output[4],
    0,
    output[7],
    0,
    0,
    1,
    0,
    output[2],
    output[5],
    0,
    1
  ].map(function(x) {
    return round(x, 6);
  });

  return `matrix3d(${matrix})`;
}

export default {
  props: {
    frameSpec: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      containerWidth: 0
    };
  },
  computed: {
    src() {
      const artwork = this.frameSpec.getArtwork();
      return artwork.customerSpecified && artwork.customerSpecified.url || artwork.croppedAndNormalized && artwork.croppedAndNormalized.url;
    },
    parsedConfigObjects () {
      return this.frameSpec.variant.maskedRenderer.map((maskedRenderer) => {
        return JSON.parse(maskedRenderer.config);
      })
    },
    sourcePoints() {
      return this.parsedConfigObjects[0].source;
    },
    targetPoints () {
      return this.parsedConfigObjects[0].target;
    },
    backgroundUrl () {
      return this.frameSpec.variant.maskedRenderer[0].image.url;
    },
    maskWrapperCssProps() {
      return {
        "--scale": `scale(${this.scale})`
      };
    },
    maskCssProps() {
      return {
        backgroundImage: `url(${this.backgroundUrl})`
      };
    },
    svgCssProps() {
      return {
        transform: this.matrixTransformStyle,
        transformOrigin: this.transformOrigin
      };
    },
    scale() {
      try {
        return this.containerWidth / 640;
      } catch (error) {
        return 1;
      }
    },
    // The third source point shall be the bottom right corner and therefore Width and height
    imageWidth() {
      return this.sourcePoints[2][0];
    },
    imageHeight() {
      return this.sourcePoints[2][1];
    },
    containedImage() {
      return contain(640, 640, this.imageWidth, this.imageHeight);
    },
    transformOrigin() {
      const { x, y } = this.containedImage;
      return `${x}px ${y}px 0px`;
    },
    translateOffset() {
      const { x, y } = this.containedImage;
      return `translate(${x},${y})`;
    },
    matrixTransformStyle() {
      return matrixTransformStyle(this.sourcePoints, this.targetPoints);
    }
  },
  mounted() {
    this.containerWidth = getInnerSize(this.$el.parentElement)?.width;
    // Rate limiting on pages with lotsoproducts
    window.addEventListener("resize", () => {
      this.containerWidth = getInnerSize(this.$el.parentElement)?.width;
    });
  },
  destroyed(){
    window.removeEventListener("resize", () => {
      this.containerWidth = getInnerSize(this.$el.parentElement)?.width;
    });
  },
};
</script>

<style lang="scss">
.mask-wrapper {
  transform: var(--scale);
  transform-origin: top left;

  .mask {
    background-size: cover;
    height: 640px;
    left: 0;
    top: 0;
    width: 640px;
    z-index: 1;
  }
  
  .image {
    z-index: 0;
  }
}
</style>
