import { createApp } from 'vue';
import ProductItem from '../components/product-item/ProductItem';

function productItemApp(opts) {
  const config = {
    dom: {
      parent: 'collection',
    },
    cls: {
      hasQuickview: 'has-quickview',
      templateCollection: 'template-collection',
    },
  };

  const c = __.extend(config, opts);

  // Globals
  const $productItems = document.querySelectorAll(`[id^="${c.dom.parent}-productItemApp"],[id*=" ${c.dom.parent}-productItemApp"]`);
  const $templateCollection = document.querySelector(`body.${c.cls.templateCollection}`);
  const $parent = document.getElementById(c.dom.parent);
  const uploadedArtwork = localStorage.getItem('uploadedArtwork');
  const hasUploadedArtwork = uploadedArtwork !== null && uploadedArtwork !== '';
  let isQuickview = $parent ? __.hasClass($parent, c.cls.hasQuickview) : null;

  if (hasUploadedArtwork && $templateCollection) {
    isQuickview = true;
  }

  function init() {
    if (!$productItems || $productItems.length === 0) return;
    productItemVueApp();
    updateJsonLd($productItems);
  }

  /**
   * Update JSON-LD
   */
  function updateJsonLd(newProductNodes) {
    if (!$templateCollection) {
      return;
    }

    const jsonLdScripts = document.querySelectorAll('script[type="application/ld+json"]');
    let jsonLdData = null;
    let targetScript = null;

    // Find the correct JSON-LD script
    jsonLdScripts.forEach((script) => {
      const data = JSON.parse(script.innerHTML);
      if (data && data.mainEntity && data.mainEntity.itemListElement) {
        jsonLdData = data;
        targetScript = script;
      }
    });

    if (!jsonLdData || !targetScript) {
      console.error('JSON-LD script with mainEntity not found');
      return;
    }

    // Process nodes, skipping the first 12 and any already processed
    newProductNodes.forEach((node, index) => {
      if (index >= 12 && !node.dataset.jsonLdProcessed) {
        const data = node.dataset;
        jsonLdData.mainEntity.itemListElement.push({
          '@type': 'ListItem',
          position: jsonLdData.mainEntity.itemListElement.length + 1,
          url: `${window.location.origin}/${data.collectionHandle}/${data.handle}`,
          name: data.productTitle,
          description: data.productSubtitle, // Assuming subtitle is used as description
          image: `https:${data.imageSrc}`,
        });

        // Mark this product as processed
        node.dataset.jsonLdProcessed = 'true';
      }
    });

    targetScript.innerHTML = JSON.stringify(jsonLdData, null, 2);
  }

  function productItemVueApp() {
    $productItems.forEach(($productItem) => {
      if (hasUploadedArtwork && $templateCollection) {
        __.addClass($productItem.parentNode, 'js-quickview-button');
      }
      // eslint-disable-next-line no-underscore-dangle
      const isMounted = $productItem?.__vue_app__ || false;
      // ensure we don't mount the app after pagination
      if (isMounted) return;

      const { id } = $productItem;
      const { productSubtitle, giftCard: giftCardTitle } = $productItem.dataset;
      const { productBadge } = $productItem.dataset;
      const { productDimensions } = $productItem.dataset;
      const { isCollectionPage } = $productItem.dataset;
      const { isSearchPage } = $productItem.dataset;
      const { selectedVariantId } = $productItem.dataset;
      const $sdgScript = $productItem.previousElementSibling;
      const product = $sdgScript
        ? formatData($sdgScript, 'product')
        : null;
      const images = $sdgScript
        ? formatData($sdgScript, 'image')
        : null;
      const altVariantImagesArray = $sdgScript
        ? formatData($sdgScript, 'productCardAssets')
        : null;

      const colorFilterValuesArray = $sdgScript
        ? formatData($sdgScript, 'colorFilterValues')
        : null;

      const maskedValuesArray = $sdgScript
        ? formatData($sdgScript, 'maskedRendering')
        : null;
      const variantSpecArray = $sdgScript
        ? formatData($sdgScript, 'variantSpec')
        : null;
      const comparePanelVariantAssetsArray = $sdgScript
        ? formatData($sdgScript, 'comparePanelVariantAssets')
        : null;

      const swatches = $sdgScript
        ? formatData($sdgScript, 'swatches')
        : null;

      if (!product) return;

      const app = createApp(
        ProductItem,
        {
          product,
          images,
          isQuickview,
          swatches,
          productSubtitle,
          selectedVariantId,
          giftCardTitle,
          productBadge,
          productDimensions,
          isCollectionPage: isCollectionPage === 'true',
          isSearchPage: isSearchPage === 'true',
          altVariantImagesArray,
          colorFilterValuesArray,
          maskedValuesArray,
          comparePanelVariantAssetsArray,
          variantSpecArray,
        },
      );

      app.config.globalProperties.$config = {
        joineryHost: SDG.Data.joineryHost || 'https://staging.framebridge.com',
      };

      app.mount(`#${id}`);
    });
  }

  function formatData($el, type) {
    const dataArray = $el.innerHTML.trim().split('SDG.Data.').filter(String);
    let filteredArray;
    let formattedData;

    if ($el && type === 'product') {
      filteredArray = dataArray.filter((data) => data.includes('productJson='));
      if (!filteredArray?.length) return [];
      formattedData = filteredArray[0].replace('productJson=', '').replace('};', '}').trim();
    }

    if ($el && type === 'image') {
      filteredArray = dataArray.filter((data) => data.includes('imageJson='));
      if (!filteredArray?.length) return [];
      formattedData = filteredArray[0].replace('imageJson=', '').replace('];', ']').trim();
    }

    if ($el && type === 'productCardAssets') {
      filteredArray = dataArray.filter((data) => data.includes('alternativeVariantImages='));
      if (!filteredArray?.length) return [];
      formattedData = filteredArray[0].replace('alternativeVariantImages=', '').replace('];', ']').trim();
    }

    if ($el && type === 'swatches') {
      filteredArray = dataArray.filter((data) => data.includes('swatchArray='));
      if (!filteredArray?.length) return [];
      formattedData = filteredArray[0].replace('swatchArray=', '').replace('];', ']').trim();
      const jsonData = JSON.parse(formattedData);

      if (jsonData?.length > 0) {
        // build the array of objects
        const swatchesFromVariants = jsonData.map((swatch) => {
          const swatchObject = {};
          swatch.split(',').forEach((item) => {
            const [key, value] = item.split(':');
            // replace spaces with underscores
            const keyName = key.replace(/\s/g, '_');

            swatchObject.color = keyName;
            swatchObject.value = value;
          });
          return swatchObject;
        });

        return swatchesFromVariants;
      }
    }

    if ($el && type === 'colorFilterValues') {
      filteredArray = dataArray.filter((data) => data.includes('colorFilterValues='));
      if (!filteredArray?.length) return [];
      formattedData = filteredArray[0].replace('colorFilterValues=', '').replace('];', ']').trim();
    }

    if ($el && type === 'maskedRendering') {
      filteredArray = dataArray.filter((data) => data.includes('maskedRendering='));
      if (!filteredArray?.length) return [];
      formattedData = filteredArray[0].replace('maskedRendering=', '').replace('];', ']').trim();
    }

    if ($el && type === 'variantSpec') {
      filteredArray = dataArray.filter((data) => data.includes('variantSpec='));
      if (!filteredArray?.length) return [];
      formattedData = filteredArray[0].replace('variantSpec=', '').replace('];', ']').trim();
    }

    if ($el && type === 'comparePanelVariantAssets') {
      filteredArray = dataArray.filter((data) => data.includes('comparePanelVariantAssets='));
      if (!filteredArray?.length) return [];
      formattedData = filteredArray[0].replace('comparePanelVariantAssets=', '').replace('];', ']').trim();
    }
    return JSON.parse(formattedData);
  }

  init();
}

export default productItemApp;
