/**
 * Carousel
 */

import Flickity from 'flickity';

function carousel(opts) {
  const config = {
    dom: {
      carousel: 'js-carousel',
      carouselCell: 'js-carousel-cell',
      carouselControls: 'hero__carousel-controls',
      carouselParent: 'hero__carousel-section',
      pageDots: 'flickity-page-dots',
      pageDot: 'dot',
      playBtnToggle: 'hero__carousel-control-play--toggle',
      nextPreviousBtnPlay: 'flickity-prev-next-button',
    },
    cls: {
      dotDark: 'dot--dark',
      dotLight: 'dot--light',
      fullHeight: 'full-height',
      isSelected: 'is-selected',
    },
    flickityOpts: {
      contain: true,
      cellAlign: 'center',
      adaptiveHeight: false,
      prevNextButtons: true,
      pageDots: true,
      draggable: true,
      dragThreshold: 10,
      wrapAround: true,
      imagesLoaded: true,
      lazyLoad: true,
      resize: true,
    },
    staticClick: null,
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options

  // cached global(s)
  const $carousels = document.querySelectorAll(`.${c.dom.carousel}`);
  const $playBtnToggle = document.querySelectorAll(`.${c.dom.playBtnToggle}`);
  let $playBtn;
  let view;

  /**
   * Init
   */
  function init() {
    if ($carousels.length > 0) {
      addEvents();
      responsive();
      __.windowResize(responsive);
    }
  }

  /**
   * Make carousel
   */
  function makeCarousel() {
    $carousels.forEach(($carousel) => {
      const $carouselItems = $carousel.querySelectorAll(`.${c.dom.carouselCell}`);
      let flkty = Flickity.data($carousel);

      if (flkty) {
        flkty.destroy();

        [...$carouselItems].forEach(($item) => {
          __.removeClass($item, c.cls.fullHeight);
        });
      }

      c.flickityOpts.on = {
        ready: () => {
          const $pageDotParent = $carousel.querySelector(`.${c.dom.pageDots}`);
          const $pageDots = $pageDotParent ? $pageDotParent.querySelectorAll(`.${c.dom.pageDot}`) : null;
          const $currentSlide = $carousel.querySelector(`.${c.dom.carouselCell}.${c.cls.isSelected}`);
          let dotStyle = $currentSlide?.dataset.dotStyle || 'dot--dark';
          if (view === 'desktop' && $currentSlide?.dataset.dotStyleDesktop) {
            dotStyle = $currentSlide.dataset.dotStyleDesktop;
          } else if (view === 'mobile' && $currentSlide?.dataset.dotStyleMobile) {
            dotStyle = $currentSlide.dataset.dotStyleMobile;
          }
          const $nextPreviousBtnPlay = $carousel.querySelectorAll(`.${c.dom.nextPreviousBtnPlay}`);

          if ($pageDots) {
            $pageDots.forEach(($dot) => {
              __.removeClass($dot, c.cls.dotDark);
              __.removeClass($dot, c.cls.dotLight);
              __.addClass($dot, dotStyle);
            });
          }

          [...$carouselItems].forEach(($item) => {
            __.addClass($item, c.cls.fullHeight);
          });

          // Next/Previous Button Play
          if ($nextPreviousBtnPlay) {
            $nextPreviousBtnPlay.forEach(($btn) => {
              $btn.addEventListener('click', (e) => {
                handlePlay(e);
              });
            });
          }
        },
        change: () => {
          const $pageDotParent = $carousel.querySelector(`.${c.dom.pageDots}`);
          const $pageDots = $pageDotParent ? $pageDotParent.querySelectorAll(`.${c.dom.pageDot}`) : null;
          const $currentSlide = $carousel.querySelector(`.${c.dom.carouselCell}.${c.cls.isSelected}`);
          let dotStyle = $currentSlide?.dataset.dotStyle || 'dot--dark';
          if (view === 'desktop' && $currentSlide?.dataset.dotStyleDesktop) {
            dotStyle = $currentSlide.dataset.dotStyleDesktop;
          } else if (view === 'mobile' && $currentSlide?.dataset.dotStyleMobile) {
            dotStyle = $currentSlide.dataset.dotStyleMobile;
          }

          if ($pageDots) {
            $pageDots.forEach(($dot) => {
              __.removeClass($dot, c.cls.dotDark);
              __.removeClass($dot, c.cls.dotLight);
              __.addClass($dot, dotStyle);
            });
          }
        },
        dragEnd: () => {
          const newFlkty = $carousel ? new Flickity(`.${c.dom.carousel}`) : null;
          const playerState = newFlkty ? newFlkty.player.state : null;
          const $currentCarouselPlayBtn = $carousel ? $carousel.querySelector(`.${c.dom.playBtnToggle}`) : null;

          if (!newFlkty || !$currentCarouselPlayBtn) return;

          if (playerState === 'stopped' || playerState === 'paused') {
            newFlkty.playPlayer();
          }
        },
        staticClick: () => {
          if (!c.staticClick) return;
          c.staticClick();
        },
      };

      const autoPlay = __.toNumber($carousel.dataset.carouselAutoplaySpeed) * 1000;
      const flickityOpts = __.extend(c.flickityOpts, { autoPlay });

      setTimeout(() => {
        /* eslint-disable-next-line */
        flkty = new Flickity($carousel, flickityOpts);
      }, 300);
    });
  }

  /**
   * Handle Play Toggle
   * @param {Object} e - change event
   */
  function handlePlayToggle(e) {
    e.preventDefault();

    const $carouselParents = __.parents(e.currentTarget);
    const $carouselParent = $carouselParents
      ? $carouselParents.find(($parent) => $parent.classList.contains(c.dom.carouselParent))
      : null;
    const $carousel = $carouselParent
      ? $carouselParent.querySelector(`.${c.dom.carousel}`)
      : null;
    $playBtn = $carouselParent
      ? $carouselParent.querySelector(`.${c.dom.playBtnToggle}`)
      : null;
    const flkty = $carousel ? new Flickity(`.${c.dom.carousel}`) : null;

    if (!flkty) return;

    e.currentTarget.classList.toggle('playing');

    if ($carousel) {
      if (e.currentTarget.classList.contains('playing')) {
        flkty.playPlayer();
        $playBtn.setAttribute('aria-label', SDG?.Data?.carouselStrings?.pause);
      } else {
        flkty.stopPlayer();
        $playBtn.setAttribute('aria-label', SDG?.Data?.carouselStrings?.play);
      }
    }
  }

  /**
   * Handle Play
   * @param {Object} e - change event
   */
  function handlePlay(e) {
    e.preventDefault();

    const $carouselParents = __.parents(e.currentTarget);
    const $carouselParent = $carouselParents
      ? $carouselParents.find(($parent) => $parent.classList.contains(c.dom.carouselParent))
      : null;
    const $carousel = $carouselParent ? $carouselParent.querySelector(`.${c.dom.carousel}`) : null;
    const flkty = $carousel ? new Flickity(`.${c.dom.carousel}`) : null;
    const playerState = flkty ? flkty.player.state : null;

    if (!flkty) return;

    if (playerState === 'stopped' || playerState === 'paused') {
      flkty.playPlayer();
    }
  }

  /**
   * Add Events
   */
  function addEvents() {
    // Play/Pause Toggle
    $playBtnToggle.forEach(($btn) => {
      $btn.addEventListener('click', (e) => {
        handlePlayToggle(e);
      });
    });
  }

  /**
   * Responsive functions
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          makeCarousel();
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          makeCarousel();
        }
      },
    });
  }

  return init();
}

export default carousel;
