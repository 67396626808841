<template>
  <article
    ref="productItem"
    class="product-item js-product-item"
    :data-product-id="product.id"
  >
    <ProductItemGallery
      :activeColor="activeColor"
      :alt="alt"
      :badge="badge"
      :canPrintArtwork="canPrintArtwork"
      :customerUploadActive="customerUploadActive"
      :errorUploadMessage="errorUploadMessage"
      :frameSpec="frameSpec"
      :frameSpecUrl="frameSpecUrl"
      :hasMaskedRendering="showMaskRenderer"
      :isBundle="isBundle"
      :isCollectionPage="isCollectionPage"
      :isQuickview="isQuickview"
      :isUploadProcessing="isUploadProcessing"
      :productImages="productImages"
      :src="src"
      :type="type"
      :url="url"
      :isLoading="isLoading"
      :disableCarousel="disableProductItemGalleryCarousel"
    />
    <div
      :class="{
        'pi__desc-bottom--gift-card': isGiftCardPage,
        'is-loading-upload': isUploadProcessing
      }"
      class="pi__desc"
    >
      <h3 class="pi__title">
        <a
          v-if="!isQuickview"
          :href="url"
          :title="url"
          :class="{'hide' : isGiftCardPage}"
        >
          {{ title }}
        </a>
        <span
          v-else
          :class="{'hide' : isGiftCardPage}"
        >
          {{ title }}
        </span>
      </h3>
      <div
        v-if="isGiftCardPage"
        class="pi__desc-bottom"
      >
          <p
            class="pi__price pi__price--large"
          >
            {{ formattedPrice(price) }}<span v-if="hasVariedPrices">+</span>
          </p>
          <p
            class="pi__subtitle pi__subtitle--slim"
            v-if="giftCardTitle"
          >
            {{ giftCardTitle }}
          </p>
      </div>
      <div
        v-else
        class="pi__desc-bottom"
      >
        <div
          v-if="subtitle"
          class="pi__subtitle"
        >
          {{ subtitle }}
        </div>
        <div
          :class="{'pi__swatches-wrapper--swatchless' : !showSwatches || colors?.length <= 0}"
          class="pi__swatches-wrapper"
        >
          <div
            v-if="showSwatches === true"
            class="pi__swatches-list"
            role="list"
          >
            <ProductSwatches
              v-if="colors?.length > 0"
              :active-color="activeColor"
              :initial-color="initialColor"
              :colors="colors"
              :images="productImages"
              :update-image="updateImage"
              :url="url"
              :noImage="noImage"
            />
          </div>
        </div>
        <p class="pi__price"
          v-if="frameSpecPrice"
        >
          {{ formattedPrice(frameSpecPrice * 100) }}
        </p>
        <p
          v-else
          class="pi__price"
        >
          {{ formattedPrice(price) }}<span class="pi__price-plus" v-if="hasVariedPrices">+</span>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import {
  computed,
  ref,
  toRefs,
  onMounted,
  onBeforeUnmount,
} from 'vue';

import useFrameSpecData from '../composables/frame-spec';
import ProductSwatches from './ProductSwatches';
import carousel from '../../lib/carousel';
import ProductItemGallery from './ProductItemGallery';

export default {
  name: 'ProductItem',
  components: {
    ProductSwatches,
    ProductItemGallery,
  },
  props: {
    handle: String,
    product: Object,
    images: Array,
    isQuickview: Boolean,
    swatches: Array,
    productSubtitle: String,
    giftCardTitle: String,
    productBadge: String,
    productDimensions: String,
    isCollectionPage: Boolean,
    isSearchPage: Boolean,
    altVariantImagesArray: Array,
    colorFilterValuesArray: Array,
    maskedValuesArray: Array,
    comparePanelVariantAssetsArray: Array,
    selectedVariantId: Number,
    variantSpecArray: Array,
  },
  setup(props) {
    const {
      msrp,
      price,
      title,
      handle,
      type,
    } = toRefs(props.product);
    const productItem = ref(null);
    const activeColor = ref({});
    const initialColor = ref({});
    const isActive = ref(false);
    const isLoading = ref(false);
    const activeVariantId = ref(null);
    const isQuickview = ref(props.isQuickview);
    const noImage = ref(
      '//cdn.shopify.com/shopifycloud/shopify/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c.gif',
    );
    const isGiftCardPage = SDG?.Data?.template === 'product.gift-card' || false;
    const formattedPrice = (value) => __.formatPriceDisplay(value);
    const variantId = Number(props.selectedVariantId);
    const errorUploadMessage = SDG?.Data?.errorUploadMessage || 'Your image is too small to print in this frame. Please upload a larger image.';

    const url = computed(() => (
      `/products/${handle.value}`
    ));
    const hasMaskedRendering = computed(() => {
      const selectedObject = props.maskedValuesArray
        .find((item) => item.id === variantId);

      const isMasked = selectedObject
        && selectedObject.masked_rendering && selectedObject.masked_rendering.length > 0;
      return isMasked;
    });
    const productImages = computed(() => (
      props.images ?? []
    ));

    const subtitle = computed(() => (
      props.productSubtitle ?? ''
    ));

    const badge = computed(() => (
      props.productBadge ?? ''
    ));

    const dimensions = computed(() => (
      props.productDimensions ?? ''
    ));

    const totalVariants = computed(() => (
      props.product.variants.length ?? 0
    ));

    const isBundle = computed(() => (
      props.product.tags.includes('Multi-Photo Frames')
    ));

    const variants = computed(() => (
      props.product.variants.filter((variant) => variant.available) ?? []
    ));

    // Temporary hotfix: disabling carousel
    // eslint-disable-next-line no-unused-vars
    const isTableTopFrame = computed(() => (
      props.product.tags
        .map((tag) => tag.toLowerCase())
        .includes('tabletop frames')
    ));

    const isCuratedDesignFrame = computed(() => (
      type.value?.toLowerCase() === 'curated design frame'
    ));

    // eslint-disable-next-line arrow-body-style
    const disableProductItemGalleryCarousel = computed(() => {
      // Temporary hotfix: disabling carousel

      // if (isTableTopFrame.value && isCuratedDesignFrame.value) {
      //   return true;
      // }

      // return false;
      return true;
    });

    const isFrameBundle = computed(() => (
      type.value?.toLowerCase() === 'frame bundle'
    ));

    const isCustomFrame = computed(() => (
      type.value?.toLowerCase() === 'custom frame'
    ));

    const isMutliPhotoFrame = computed(() => (
      props.product.tags
        .map((tag) => tag.toLowerCase())
        .includes('multi-photo frames')
    ));

    const showSwatches = computed(() => {
      const { options } = props.product;

      if (!options?.length > 0) return false;
      const colorOption = options.filter((option) => (
        option.toLowerCase() === 'frame style' || option.toLowerCase() === 'color'
      ));

      if (colorOption?.length > 0) return true;

      return false;
    });

    const productVariants = computed(() => {
      const variantDetails = variants.value.map((variant) => {
        const { id } = variant;
        const filteredImages = [...props.altVariantImagesArray].find((obj) => obj.id === id);
        const colorFilterValues = [...props.colorFilterValuesArray].find((obj) => obj.id === id);
        const comparePanelAssets = [...props.comparePanelVariantAssetsArray].find(
          (obj) => obj.id === id,
        );

        const {
          option1,
          featured_image: featuredImage,
        } = variant;

        const details = {
          option1: option1.toLowerCase().split(' ').join('_'),
          featured_image: filteredImages?.images?.length
            ? filteredImages.images[0]
            : featuredImage,
          color_filter_values: colorFilterValues?.colors,
          compare_panel_assets: comparePanelAssets?.asset,
        };
        return details;
      });

      if (variantDetails.length > 0) {
        const variantOptions = [];
        variantDetails.forEach((value) => {
          const formattedValue = value.option1
            ? value.option1.toLowerCase().trim().replace(':', '_').replace(/ /g, '_')
            : null;

          if (formattedValue && !variantOptions.includes(formattedValue)) {
            const detail = {
              option: formattedValue,
              featured_image: value.featured_image,
              color_filter_values: value.color_filter_values,
              compare_panel_assets: value.compare_panel_assets,
            };

            variantOptions.push(detail);
          }
        });

        return variantOptions;
      }

      return [];
    });

    const hasVariedPrices = computed(() => {
      const prices = [];

      variants.value.forEach((variant) => {
        const {
          price: variantPrice,
        } = variant;

        if (!prices.includes(variantPrice)) {
          prices.push(variantPrice);
        }
      });

      if (prices.length > 1) {
        return true;
      }

      return false;
    });

    const colors = computed(() => {
      const { options } = props.product;

      const colorOption = options.filter((option) => (
        option.toLowerCase() === 'frame style' || option.toLowerCase() === 'color'
      ));

      const colorOptions = [];
      const optionsArray = [];

      if (colorOption.length > 0) {
        const variantsOptions = productVariants.value;
        const variantSwatches = props.swatches;

        variantsOptions.forEach((variantOption) => {
          const variantSwatch = variantSwatches.find((swatch) => {
            const { color } = swatch;
            const { option } = variantOption;

            if (!color || !option) return false;

            if (color === option) {
              return true;
            }

            return false;
          });

          if (variantSwatch && !colorOptions.includes(variantSwatch.color)) {
            variantSwatch.featured_image = variantOption.featured_image;
            variantSwatch.compare_panel_assets = variantOption.compare_panel_assets;

            if (variantSwatch.value.includes('//')) {
              variantSwatch.type = 'image';
            } else {
              variantSwatch.type = 'color';
            }
            // set active color based on active color filters
            const colorFilterValues = variantOption.color_filter_values;
            if (SDG?.activeColorFilters && colorFilterValues) {
              const colorFilters = colorFilterValues.map((color) => color.toLowerCase());
              SDG.activeColorFilters.some((activeColorFilter) => {
                const activeColorFilterValue = activeColorFilter.toLowerCase();
                variantSwatch.hasActiveColor = colorFilters.includes(activeColorFilterValue);
                return variantSwatch.hasActiveColor;
              });
            }
            colorOptions.push(variantSwatch.color);
            optionsArray.push(variantSwatch);
          }
        });

        return optionsArray;
      }
      return [];
    });

    const updateImage = (e) => {
      const $swatch = e.currentTarget;
      const $swatchParent = $swatch.parentElement;
      const $image = productItem.value.querySelector('.js-plp-image');
      const { image, id } = $swatch.dataset;

      if (image && $image) {
        $image.setAttribute('srcset', image);
      }

      if (id) {
        activeVariantId.value = id;

        updateSpecOnSwatchClick(activeVariantId.value);
      }

      resetSwatches();
      __.addClass($swatch, 'is-selected');
      __.addClass($swatchParent, 'is-selected');

      setTimeout(() => {
        const $piImages = productItem.value.querySelectorAll('.pi__image');

        $piImages.forEach(($piImage) => __.removeClass($piImage, 'is-loading'));
        initializeCarousel();
        $swatch.setAttribute('aria-pressed', 'true');
      }, 250);
    };

    const handleStaticClick = () => {
      productItem.value.click();
    };

    const initializeCarousel = () => {
      carousel({
        dom: {
          carousel: 'js-product-item-carousel',
          carouselCell: 'js-product-item-carousel-cell',
        },
        staticClick: handleStaticClick,
      });
    };

    const resetSwatches = () => {
      const $swatches = productItem.value.querySelectorAll('.js-product-swatch');
      $swatches.forEach(($swatch) => {
        const $swatchParent = $swatch.parentElement;
        __.removeClass($swatch, 'is-selected');
        __.removeClass($swatchParent, 'is-selected');
        $swatch.setAttribute('aria-pressed', 'false');
      });
    };

    const handleClick = () => {
      isActive.value = !isActive.value;
    };

    const resetActive = () => {
      isActive.value = false;
    };


    const activeFilteredColor = computed(() => (
      colors.value.find((color) => color.hasActiveColor) ?? null
    ));
    let src = props.images.length > 0
      ? props.images[0].src
      : noImage.value;
    let alt = props.images.length > 0
      ? props.images[0].alt
      : 'No image available';

    const activeImage = __.getSizedImageUrl(src, '1x1').replace('_1x1.', '_{width}x.');

    if (activeFilteredColor.value) {
      activeColor.value = activeFilteredColor.value;
      initialColor.value = activeFilteredColor.value;
      const activeFeaturedImage = activeFilteredColor.value.featured_image;
      src = activeFeaturedImage ? activeFeaturedImage.src : noImage.value;
      alt = activeFeaturedImage ? activeFeaturedImage.alt : 'No image available';
    } else {
      // eslint-disable-next-line prefer-destructuring
      activeColor.value = colors.value[0] ?? {};
      initialColor.value = colors.value[0] ?? {};
    }

    const initialColorValue = initialColor.value.color || initialColor.value;

    const selectedVariantObject = props.variantSpecArray
      .find((variant) => variant.option1 === initialColorValue);
    const selectedVariantSpec = selectedVariantObject?.variant_spec || null;
    activeVariantId.value = selectedVariantObject?.id;
    const selectedVariantTitle = props.variantSpecArray
      .find((variant) => variant.id === activeVariantId?.value)?.simple_title || null;

    // FrameSpec
    const {
      frameSpec,
      frameSpecPrice,
      frameSpecUrl,
      isUploadProcessing,
      canPrintArtwork,
      checkFrameSpecData,
      clearFrameSpec,
      updateSpecOnSwatchClick,
      customerUploadActive,
      showMaskRenderer,
    } = useFrameSpecData(
      props.product,
      selectedVariantTitle,
      selectedVariantSpec,
      activeVariantId.value,
    );

    // change slide on swipe
    const slide = (event, caro) => {
      const { deltaX } = event || 0;

      if (deltaX > 0) {
        caro.next();
      }

      if (deltaX < 0) {
        caro.previous();
      }
    };

    const initQuickview = () => {
      // ensure quickview is in DOM
      const $quickview = document.getElementById('quickview');

      if ($quickview) {
        const quickview = SDG?.quickview({
          type: 'single',
          wrapper: productItem.value,
        });

        // if quickview is not available
        if (!quickview) return;

        quickview?.init();
        const $parent = __.parents(productItem.value, '.js-pi') || null;
        const $links = productItem?.value?.querySelectorAll('.pi__link') || null;

        if ($parent?.length > 0) {
          $parent[0]?.classList?.add('js-quickview-button');

          if ($links?.length > 0) {
            // remove href
            [...$links].forEach(($link) => {
              $link.removeAttribute('href');
            });
          }

          // attach event
          productItem.value.addEventListener('click', (e) => {
            // exclude favoite
            if (e.target.closest('.js-product-heart')) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }

            // exclude swatches
            if (e.target.closest('.js-product-swatch')) {
              e.preventDefault();
              e.stopPropagation();
              return;
            }

            quickview.open(e);
          });
        }
      }
    };

    const destroyQuickview = () => {
      const $parent = __.parents(productItem.value, '.js-pi') || null;
      const $links = productItem?.value?.querySelectorAll('.pi__link') || null;

      if ($parent?.length > 0) {
        $parent[0]?.classList?.remove('js-quickview-button');

        if ($links?.length > 0 && url?.value) {
          // when the link el is not an anchor
          [...$links].forEach(($link) => {
            if (!($link instanceof HTMLAnchorElement)) {
              const $newLink = document.createElement('a');
              $newLink.setAttribute('href', url.value);

              // insert new link
              $link.parentNode.insertBefore($newLink, $link);
              $newLink.appendChild($link);
              return;
            }

            $link.setAttribute('href', url.value);
          });
        }

        // renmove event
        productItem?.value?.removeEventListener('click', null);
      }
    };

    onMounted(() => {
      initializeCarousel();

      if (frameSpec.value === null && !isBundle.value) {
        checkFrameSpecData(
          props.product,
          selectedVariantTitle,
          selectedVariantSpec,
          activeVariantId.value,
        );
      }

      // event to handle swatch quicks in quickview drawer
      document.addEventListener(`updateSpecOnSwatchClick-${props.product.id}`, async (event) => {
        const { id, callback } = event.detail || {};

        await updateSpecOnSwatchClick(id);
        // small timeout for html to update
        setTimeout(() => {
          if (callback && typeof callback === 'function') {
            callback();
          }
        }, 250);
      });

      // handle upload item
      window.addEventListener('uploadItem', (event) => {
        const { added, removed } = event.detail || {};

        if (added && !isBundle.value) {
          checkFrameSpecData(
            props.product,
            selectedVariantTitle,
            selectedVariantSpec,
            activeVariantId.value,
          );

          isQuickview.value = true;

          // small timeout for artwork to render
          setTimeout(() => {
            initQuickview();
          }, 500);
        }

        if (removed) {
          isLoading.value = true;
          isQuickview.value = false;
          clearFrameSpec();
          destroyQuickview();

          // small timeout to smooth remove transition
          setTimeout(() => {
            if (!frameSpec.value) {
              isLoading.value = false;
            }
          }, 500);
        }
      });
    });

    // clean up
    onBeforeUnmount(() => {
      document.removeEventListener(`updateSpecOnSwatchClick-${props.product.id}`, null);
      window.removeEventListener('uploadItem', null);
    });

    return {
      activeImage,
      frameSpec,
      canPrintArtwork,
      showMaskRenderer,
      customerUploadActive,
      errorUploadMessage,
      frameSpecPrice,
      frameSpecUrl,
      isUploadProcessing,
      msrp,
      price,
      title,
      url,
      type,
      productImages,
      subtitle,
      badge,
      dimensions,
      totalVariants,
      productItem,
      activeColor,
      initialColor,
      showSwatches,
      isActive,
      isGiftCardPage,
      noImage,
      src,
      alt,
      variants,
      hasVariedPrices,
      colors,
      formattedPrice,
      handleClick,
      resetActive,
      updateImage,
      slide,
      hasMaskedRendering,
      isBundle,
      isLoading,
      joineryHost: SDG?.Data?.joineryHost || 'https://staging.framebridge.com',
      isCuratedDesignFrame,
      disableProductItemGalleryCarousel,
      isMutliPhotoFrame,
      isFrameBundle,
      isCustomFrame,
    };
  },
};
</script>
