import trapFocus from './trap-focus';

function collectionFilters(opts) {
  const config = {
    dom: {
      collection: 'collection',
      filtersWrapper: 'filtersWrapper',
      filtersDrawerTrigger: 'filtersDrawerTrigger',
      toolbar: 'collectionToolbar',
      productsGrid: 'productsGrid',
      desktopAppendWrapper: 'desktopAppendWrapper',
      drawerClose: 'filtersDrawerClose',
      filterGroup: 'js-filter-group',
      filterGroupDisplay: 'js-filter-group-display',
      filterGroupColors: 'js-filter-group-colors',
      filterForm: 'filterForm',
      appliedCount: 'applyFiltersCount',
      clearAllMobile: 'js-clear-all-mobile',
      modalFiltersOverlay: 'modalFiltersOverlay',
      activeFiltersDisplay: 'js-active-filter-size',
      activeFiltersDisplayDesktop: 'js-active-filter-size-desktop',
      productSwatch: 'js-product-swatch',
      productSwatchWrapper: 'js-product-swatch-wrapper',
      desktopFilterForm: 'desktopFilterForm',
      sidebarBtn: 'js-filter-sidebar-btn',
      sidebarBtnAbs: 'js-filter-sidebar-btn-abs',
      sidebarFilterGroup: 'sidebar-filter-group',
      productsContainer: 'js-products-container',
      filterGroupContainer: 'js-filter-group-container',
      clearAllDesktop: 'js-clear-all-desktop',
    },
    cls: {
      active: 'is-active',
      closed: 'is-closed',
      hide: 'hide',
      open: 'is-open',
      selected: 'is-selected',
      fullWidth: 'full-width',
      bodyNoScroll: 'body--no-scroll',
      productsPerRowThree: 'products-per-row--three',
      productsPerRowFour: 'products-per-row--four',
      productsPerRowOne: 'products-per-row--one',
    },
  };

  // Shorthand config
  const c = __.extend(config, opts); // Extend defaults with passed options

  // cached global(s)
  const $toolbar = document.getElementById(c.dom.toolbar);
  const $filtersWrapper = document.getElementById(c.dom.filtersWrapper);
  const $filtersTrigger = document.getElementById(c.dom.filtersDrawerTrigger);
  const $desktopAppendWrapper = document.getElementById(c.dom.desktopAppendWrapper);
  const $filterGroups = document.querySelectorAll(`.${c.dom.filterGroup}`);
  const $mobileFilterGroups = document.querySelectorAll(`.${c.dom.filterGroup}:not(.${c.dom.sidebarFilterGroup})`);
  const $desktopFilterGroups = document.querySelectorAll(`.${c.dom.filterGroup}.${c.dom.sidebarFilterGroup}`);
  const $appliedCount = document.getElementById(c.dom.appliedCount);
  const overlay = document.getElementById(c.dom.modalFiltersOverlay);
  const $desktopFilterForm = document.getElementById(c.dom.desktopFilterForm);
  const $sidebarBtn = document.querySelector(`.${c.dom.sidebarBtn}`);
  const $sidebarBtnAbs = [...document.querySelectorAll(`.${c.dom.sidebarBtnAbs}`)];
  const $productsGrid = document.getElementById(c.dom.productsGrid);
  const $productsContainer = document.querySelector(`.${c.dom.productsContainer}`);
  const $filterGroupContainer = document.querySelector(`.${c.dom.filterGroupContainer}`);
  const $filterForm = document.getElementById(c.dom.filterForm);
  const $desktopClearAllButton = [...document.querySelectorAll(`.${c.dom.clearAllDesktop}`)];

  const filters = new Set();
  let view;

  /**
   * Init
   */
  function init() {
    if (!$toolbar) return;
    responsive();
    __.windowResize(responsive);
    addEvents();
    setActiveFiltersSize();
    setUpProductGrid();
    initSizeRangeInput();
    scrollToCollectionTop();
  }

  /**
   * Scroll to Collection Top
   */
  function scrollToCollectionTop() {
    const { hash } = window.location;

    if (!hash) {
      return;
    }

    const $collection = document.querySelector(hash);

    if (!$collection) {
      return;
    }

    setTimeout(() => {
      $collection.scrollIntoView();
    }, 1000);
  }

  /**
   * Set Up Product Grid
   */
  function setUpProductGrid() {
    if (!$productsGrid) return;

    if (view === 'desktop') {
      const { initialGridSize } = $productsGrid.dataset;
      if (initialGridSize === c.cls.productsPerRowFour) {
        return;
      }

      __.removeClass($productsGrid, c.cls.productsPerRowFour);
      __.addClass($productsGrid, c.cls.productsPerRowThree);
    } else if (view === 'mobile') {
      __.addClass($productsGrid, c.cls.productsPerRowOne);
    }
  }

  /**
   * Set Active Filters Size
   */
  function setActiveFiltersSize() {
    const $activeFiltersDisplayElements = [...document.querySelectorAll(`.${c.dom.activeFiltersDisplay}`)];
    const activeFilters = document.querySelectorAll('input[type="checkbox"]:checked');

    if ($activeFiltersDisplayElements?.length && activeFilters) {
      $activeFiltersDisplayElements.forEach(($activeFiltersDisplayElement) => {
        const isDesktop = $activeFiltersDisplayElement.classList
          .contains(c.dom.activeFiltersDisplayDesktop);

        if (isDesktop) {
          $activeFiltersDisplayElement.innerHTML = activeFilters?.length ? `&nbsp;(${activeFilters?.length})` : '';
        } else {
          $activeFiltersDisplayElement.innerHTML = activeFilters?.length;
        }
      });
    }
  }

  /**
   * Add Events
   */
  function addEvents() {
    __.addEvent({
      id: $filtersTrigger,
      event: 'click',
      fn: toggleFiltersDrawer,
    });

    __.addEvent({
      id: c.dom.drawerClose,
      event: 'click',
      fn: toggleFiltersDrawer,
    });

    __.addEvent({
      id: overlay,
      event: 'click',
      fn: deskDrawerSetup,
    });

    __.addEvent({
      id: $toolbar,
      event: 'click',
      className: c.dom.filterGroup,
      fn: handleFilterGroupToggle,
    });

    __.addEvent({
      id: c.dom.filterForm,
      event: 'submit',
      fn: interceptFormSubmit,
    });

    __.addEvent({
      id: c.dom.desktopFilterForm,
      event: 'submit',
      fn: interceptFormSubmit,
    });

    __.addEvent({
      id: c.dom.filterForm,
      event: 'change',
      fn: onFilterChange,
    });

    __.addEvent({
      id: c.dom.desktopFilterForm,
      event: 'change',
      fn: onFilterChange,
    });

    __.addEvent({
      id: $sidebarBtn,
      event: 'click',
      fn: toggleFiltersSidebar,
    });

    $sidebarBtnAbs.forEach(($btn) => {
      __.addEvent({
        id: $btn,
        event: 'click',
        fn: toggleFiltersSidebar,
      });
    });

    $desktopClearAllButton.forEach(($btn) => {
      __.addEvent({
        id: $btn,
        event: 'click',
        fn: clearAllFilters,
      });
    });
  }

  /**
   * Intercept Form Submit
   * @param {Object} e - submit event
   */
  function interceptFormSubmit(e) {
    const $form = e.currentTarget;
    e.preventDefault();
    if (window.location.pathname === '/search') {
      const activeParams = setUrlParams();
      const newPath = `${window.location.href}${activeParams}`;
      window.history.replaceState(null, '', newPath);
      window.location.reload();
    } else {
      $form.submit();
    }
  }

  /**
   * Remove Desktop Form
   */
  function removeDesktopForm() {
    if ($filterForm && $filterGroupContainer && !$filterForm.contains($filterGroupContainer)) {
      $filterForm.insertBefore($filterGroupContainer, $filterForm.lastElementChild);
    }
    if ($desktopFilterForm && $desktopFilterForm.parentNode) {
      $desktopFilterForm.parentNode.removeChild($desktopFilterForm);
    }
  }

  /**
   * Add Desktop Form
   */
  function addDesktopForm() {
    if ($filterForm && $filterGroupContainer && $filterForm.contains($filterGroupContainer)) {
      $filterForm.removeChild($filterGroupContainer);
    }
    if ($desktopFilterForm && $productsContainer
      && !$productsContainer.contains($desktopFilterForm)) {
      $productsContainer.insertBefore($desktopFilterForm, $productsContainer.firstChild);
    }
  }

  /**
   * Toggle Filters Sidebar
   * @param {event} e - click event
   */
  function toggleFiltersSidebar(e) {
    e.preventDefault();
    if (__.hasClass($desktopFilterForm, c.cls.hide)) {
      __.removeClass($desktopFilterForm, c.cls.hide);
      __.addClass($productsGrid, c.cls.productsPerRowThree);
      __.removeClass($productsGrid, c.cls.productsPerRowFour);
      __.addClass($sidebarBtn, c.cls.hide);
      $sidebarBtnAbs.forEach(($btn) => __.removeClass($btn, c.cls.hide));
    } else {
      __.addClass($desktopFilterForm, c.cls.hide);
      __.removeClass($productsGrid, c.cls.productsPerRowThree);
      __.addClass($productsGrid, c.cls.productsPerRowFour);
      __.removeClass($sidebarBtn, c.cls.hide);
      $sidebarBtnAbs.forEach(($btn) => __.addClass($btn, c.cls.hide));
    }
  }

  /**
   * Set URL Params
   */
  function setUrlParams() {
    let url = '';
    filters.forEach((filter) => {
      url += `&${filter}`;
    });
    return url;
  }

  /**
   * On filter change
   * @param {event} e - change event
   */
  function onFilterChange(e) {
    if (e.target.type === 'range') {
      const $sizeFilterLabels = [...document.querySelectorAll('.js-filter-sidebar-size-filter-label')];
      const $sizeFilterHiddenCheckboxes = [...document.querySelectorAll('input[name="filter.v.option.size')];
      e.preventDefault();

      $sizeFilterLabels.forEach(($sizeFilterLabel) => {
        if ($sizeFilterLabel.dataset.sizeIndex === e.target.value) {
          $sizeFilterLabel.classList.add(c.cls.active);
        } else {
          $sizeFilterLabel.classList.remove(c.cls.active);
        }
      });

      $sizeFilterHiddenCheckboxes.forEach(($sizeFilterHiddenCheckbox) => {
        if ($sizeFilterHiddenCheckbox.dataset.sizeIndex === e.target.value) {
          const { name, value } = $sizeFilterHiddenCheckbox;
          $sizeFilterHiddenCheckbox.checked = true;
          addUrlParam(name, value);
        } else {
          const { name, value } = $sizeFilterHiddenCheckbox;
          $sizeFilterHiddenCheckbox.checked = false;
          removeUrlParam(name, value);
        }
      });
      return;
    }

    const { name, value } = e.target;
    const isFacetActive = e.target.checked;

    if (isFacetActive) {
      addUrlParam(name, value);
    } else {
      removeUrlParam(name, value);
    }
  }

  /**
   * Mobile Drawer Setup
   */
  function mobileDrawerSetup() {
    $filterGroups.forEach(($filterGroup) => {
      __.addClass($filterGroup, c.cls.open);
      $filterGroup.setAttribute('open', '');
    });
  }

  /**
   * Desk Drawer Setup
   */
  function deskDrawerSetup(toggle) {
    if (view !== 'desktop') return;

    $mobileFilterGroups.forEach(($filterGroup) => {
      __.removeClass($filterGroup, c.cls.open);
      __.removeClass(overlay, c.cls.open);
      $filterGroup.removeAttribute('open', '');
    });
    if (!toggle) {
      $desktopFilterGroups.forEach(($filterGroup) => {
        __.addClass($filterGroup, c.cls.open);
        $filterGroup.setAttribute('open', '');
      });
    }
  }

  /**
   * Handle Filter Group Toggle
   */
  function handleFilterGroupToggle(e) {
    if (!e?.currentTarget) return;
    if (__.hasClass(e.currentTarget, c.cls.open)) {
      deskDrawerSetup(true);
      return;
    }

    deskDrawerSetup(true);
    __.addClass(e.currentTarget, c.cls.open);
    __.addClass(overlay, c.cls.open);
  }

  /**
   * Toggle Filters Drawer
   */
  function toggleFiltersDrawer() {
    $filtersWrapper.classList.toggle(c.cls.closed);
    document.body.classList.toggle(c.cls.bodyNoScroll);
    trapFocus($filtersWrapper);
    mobileDrawerSetup();
  }

  /**
   * Remove Existing Sort Params
   */
  function removeExistingSortParams() {
    filters.forEach((filter) => {
      const [name, value] = filter.split('=');
      if (name === 'sort_by') {
        filters.delete(`${name}=${value}`);
      }
    });
  }

  /**
   * Responsive
   */
  function responsive() {
    __.mq({
      view: 'desktop',
      callback: () => {
        if (view !== 'desktop') {
          view = 'desktop';
          deskDrawerSetup();
          $desktopAppendWrapper.appendChild($filtersWrapper);
          __.removeClass($filtersWrapper, c.cls.closed);
          addDesktopForm();
        }
      },
    });

    __.mq({
      view: 'mobile',
      callback: () => {
        if (view !== 'mobile') {
          view = 'mobile';
          document.body.appendChild($filtersWrapper);
          __.addClass($filtersWrapper, c.cls.closed);
          trapFocus($filtersWrapper);
          removeDesktopForm();
        }
      },
    });
  }

  /**
   * Update Mobile Button UX
   */
  function updateMobileButtonUX() {
    const checkedInputs = document.querySelectorAll('input[type="checkbox"]:checked');
    if (!$appliedCount) return;
    const clearBtnMobile = document.querySelector(`.${c.dom.clearAllMobile}`);
    let buttonText = '';
    buttonText = `Apply (${checkedInputs.length}) Filters`;
    __.removeClass(clearBtnMobile, c.cls.hide);
    $appliedCount.removeAttribute('disabled', '');
    $appliedCount.innerHTML = buttonText;
  }

  /**
   * Add URL Param
   * @param {String} key
   * @param {String} value
   */
  function addUrlParam(key, value) {
    if (view === 'desktop') {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.has('sort_by')) {
        searchParams.delete('sort_by');
      }
      searchParams.append(key, value);
      const newPath = `${window.location.pathname}?${searchParams.toString()}#collection`;
      window.history.replaceState(null, '', newPath);
      window.location.reload();
    } else {
      removeExistingSortParams();
      filters.add(`${key}=${encodeURIComponent(value)}`);
      updateMobileButtonUX();
    }
  }

  /**
   * Remove URL param
   * @param {String} key
   * @param {String} value
   */
  function removeUrlParam(key, value) {
    if (view === 'desktop') {
      const searchParams = new URLSearchParams(window.location.search);
      const entries = searchParams.getAll(key);
      const newEntries = entries.filter((entry) => entry !== value);

      searchParams.delete(key);
      newEntries.forEach((newEntry) => searchParams.append(key, newEntry));
      const newPath = `${window.location.pathname}?${searchParams.toString()}#collection`;
      window.history.replaceState(null, '', newPath);
      window.location.reload();
    } else {
      filters.delete(`${key}=${encodeURIComponent(value)}`);
      updateMobileButtonUX();
    }
  }

  function initSizeRangeInput() {
    const $sizeRangeInput = document.querySelector('.filter-group-display__range-input');

    if (!$sizeRangeInput) return;

    const $sizeFilterHiddenCheckboxes = [...document.querySelectorAll('input[name="filter.v.option.size')] || [];
    const $sizeFilterLabels = [...document.querySelectorAll('.js-filter-sidebar-size-filter-label')] || [];
    const $sizeFilterDescriptions = [...document.querySelectorAll('.js-filter-sidebar-size-filter-description')] || [];
    const $activeSizeFilter = $sizeFilterHiddenCheckboxes?.find(({ checked }) => checked) || null;
    const sizeIndex = $activeSizeFilter?.dataset?.sizeIndex || null;

    if ($sizeFilterLabels?.length > 0 && sizeIndex) {
      $sizeFilterLabels?.forEach(($sizeFilterLabel) => {
        if ($sizeFilterLabel?.dataset?.sizeIndex === sizeIndex) {
          $sizeFilterLabel.classList.add(c.cls.active);
        } else {
          $sizeFilterLabel.classList.remove(c.cls.active);
        }
      });
    }

    if ($sizeFilterDescriptions?.length > 0 && sizeIndex) {
      $sizeFilterDescriptions?.forEach(($sizeFilterDescription) => {
        if ($sizeFilterDescription.dataset.sizeIndex === sizeIndex) {
          $sizeFilterDescription.classList.add(c.cls.active);
        } else {
          $sizeFilterDescription.classList.remove(c.cls.active);
        }
      });
    }

    $sizeRangeInput.value = $activeSizeFilter?.dataset?.sizeIndex || 0;
  }

  function clearAllFilters(e) {
    e.preventDefault();

    const searchParams = new URLSearchParams(window.location.search);

    searchParams.forEach((value, key) => {
      if (!key.includes('filter')) {
        return;
      }

      searchParams.delete(key);
      const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
      const newPathWithParams = `${window.location.pathname}${paramsString}`;
      window.history.replaceState(null, '', newPathWithParams);
      window.location.reload();
    });
  }

  return init();
}

export default collectionFilters;
