import productItemApp from './product-item-app';
import collectionQuickview from './collection-quickview';

/**
 * Collection Pagination
 */
function collectionPagination() {
  const c = {
    dom: {
      collection: 'collection',
      collectionProducts: 'productsGrid',
      collectionPagination: '.js-collection-pagination',
      collectionItem: '.js-pi',
      next: '.next a',
    },
    cls: {
      hide: 'hide',
    },
    isIntersecting: false,
    observer: null,
  };

  // Globals
  const $collection = document.getElementById(c.dom.collection);
  const $collectionProducts = document.getElementById(c.dom.collectionProducts);
  const $collectionPagination = $collection?.querySelector(c.dom.collectionPagination);
  let $next = $collectionPagination?.querySelector(c.dom.next) || null;

  let isLoading = false;

  /**
   * Init
   */
  function init() {
    if (!$collection || !$next) return;

    __.addEvent({
      id: document,
      event: 'scroll',
      fn: () => {
        __.debounce(fetchWhenScrolledOverHalfCollection(), 500);
      },
    });
  }

  function fetchWhenScrolledOverHalfCollection() {
    const currentScroll = window.scrollY;
    const collectionHeight = $collection.offsetHeight;
    const seventyFivePercentHeight = (collectionHeight * 0.75);
    const isScrolledOverSeventyFivePercent = currentScroll > seventyFivePercentHeight;

    if (currentScroll > collectionHeight) return;

    if (isScrolledOverSeventyFivePercent && $next) {
      if (!isLoading) {
        isLoading = true;
        fetchMoreData();
      }
    }
  }

  /**
   * Fetch more data
   */
  function fetchMoreData() {
    __.ajax({
      error: onError,
      success: onSuccess,
      type: 'GET',
      url: `${$next.getAttribute('href')}`,
    });
  }

  /**
   * Handle error
   */
  function onError(error) {
    console.warn('onError', error);
    isLoading = false;
  }

  /**
   * Handle success
   */
  function onSuccess(data) {
    if (data) {
      const $nextCollection = new DOMParser().parseFromString(data, 'text/html');
      const $nextCollectionProducts = $nextCollection.getElementById(c.dom.collectionProducts);
      const $nextCollectionPagination = $nextCollection.querySelector(c.dom.collectionPagination);
      const $newNext = $nextCollectionPagination?.querySelector(c.dom.next);

      if ($collectionProducts
          && $nextCollectionProducts
          && $nextCollectionProducts.querySelectorAll(c.dom.collectionItem).length > 0
      ) {
        $collectionProducts.append(...$nextCollectionProducts.childNodes);
        productItemApp({
          dom: {
            parent: 'collection',
          },
        });
        // set quick view and video events after new products and touts are added
        collectionQuickview();
        SDG.videoHTML();
      }

      if ($newNext) {
        $next = $newNext;
      } else {
        $next = false;
      }

      // setTimout to settle
      setTimeout(() => {
        isLoading = false;
      }, 300);
    }
  }

  return init();
}

export default collectionPagination;
