<template>
  <div>
    <button @click="triggerImageUpload">{{ title }}</button>

    <input
      v-show="false"
      ref="fileField"
      type="file"
      name="artwork-upload-button"
      label="Upload Image"
      accept="image/gif, image/jpeg, image/png, image/heic"
      @change="uploadImage($event)"
    >
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import GlazeClient from "./apihelpers/glazeClient";
import artworkUploader from "./apihelpers/artworkUploader";
import { normalizedArtworkData } from "./framingUtilities.js";
import { setArtworkSizeDefaults } from "./artwork";
import { getImageErrorsForFile } from "./validateImageFile.js";
import { heicToJpeg } from './heicConverter';

const component = {
  data () {
    return {
      activeUploads: 0,
      fileSelected: false
    };
  },
  props: {
    load: Function,
    checkForQuality: {
      type: Boolean,
      required: false,
      default: true
    },
    dragAndDropEvent: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    fixedArtWidth: {
      type: Number,
      required: false,
      default: null
    },
    fixedArtHeight: {
      type: Number,
      required: false,
      default: null
    },
    externalUploadUrl: {
      type: String,
      required: false,
      default: null
    },
    conveyance: {
      type: String,
      required: false,
      default: null
    },
    processingUpload: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    joineryHost: {
      type: String,
      required: true,
      default: "/"
    },
    title: {
      type: String,
      required: false,
      default: "Upload"
    }
  },
  watch: {
    dragAndDropEvent () {
      this.uploadImage(this.dragAndDropEvent);
    },
    externalUploadUrl () {
      this.uploadImage(this.externalUploadUrl);
    }
  },
  computed: {
    GlazeClient () {
      let token;

      return new GlazeClient(token, { baseUrl: this.joineryHost });
    }
  },
  methods: {
    triggerImageUpload () {
      this.$emit("processing-upload");
      this.$refs.fileField.value = null;
      this.$refs.fileField.click();
    },
    /**
     * Called when artwork is on s3, and reported to glaze, but thumbanils are not
     * yet processed.
     */
    onArtworkCreate (upload, artwork) {
      upload.artwork = { ...artwork };
      this.$emit("artwork-create", upload);
    },
    async createFileFromUrl (url) {
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: "image/jpeg"
      };
      // were only using instagram for this for now,
      // if we support uploads from elsewhere, change this name
      return new File([data], "artwork.jpg", metadata);
    },
    async convertHeicToJpeg (file) {
      let blob;

      try {
        blob = await heicToJpeg(file);
      } catch (error) {
        if (error?.message === "ERR_USER Image is already browser readable: image/jpeg") {
          // If the file is already a jpeg, just use it as is
          blob = file;
        } else {
          this.$emit("upload-error", "Error converting HEIC to JPEG");
          console.error("Error converting HEIC to JPEG", error);
        }
      }

      const metadata = {
        type: "image/jpeg"
      };

      return new File([blob], "artwork.jpg", metadata);
    },
    async uploadImage (event) {
      this.fileSelected = true;
      let files;

      if (typeof event === "string") {
        const fileFromUrl = await this.createFileFromUrl(event);
        files = [fileFromUrl];
      } else {
        files = (event.target && event.target.files) || event.dataTransfer.files;
      }

      const uploadGroupUUID = uuid();

      if (!files[0]) {
        return;
      } else if (files[0].type.includes("heic")) {
        files = [await this.convertHeicToJpeg(files[0])];
      }

      for (const file of files) {
        const reader = new FileReader();

        const fixedArtWidth = parseFloat(this.fixedArtWidth) || 0;
        const fixedArtHeight = parseFloat(this.fixedArtHeight) || 0;

        try {
          const value = await getImageErrorsForFile(
            file, reader, this.checkForQuality, fixedArtWidth, fixedArtHeight
          );

          this.activeUploads++;
          this.$emit("image-selected", value.img);

          const upload = {
            uuid: uuid(),
            groupId: uploadGroupUUID,
            startedAt: new Date(),
            file
          };

          this.$emit("upload-initiated", upload);

          const response = await artworkUploader({
            file,
            client: this.GlazeClient,
            artwork: {
              convex_metadata: value,
              use_convex: true
            },
            onUploadProgress: (event) => {
              this.$emit("upload-progress", event);
            },
            onArtworkCreate: (artwork) => {
              upload.processingStartedAt = new Date();

              this.onArtworkCreate(upload, normalizedArtworkData(artwork));
            }
          });

          upload.processingFinishedAt = new Date();

          upload.artwork = setArtworkSizeDefaults(
            normalizedArtworkData(response.data),
            { sizeDefault: "minForSizeGroup", sizeGroup: "S" }
          );

          this.$emit("upload-complete", upload);
          this.activeUploads -= 1;
        } catch (reason) {
          this.$emit("upload-error", reason);
          this.fileSelected = false;
        }
      }
    }
  }
};

export default component;
</script>

<style lang="scss" scoped>
  button {
    border: 0 solid transparent;
  }

  .no-outline {
    outline: 0;
  }
</style>
